<script>
const base_script = `
**Notebook 1: Introduction**

"Welcome, dear student. I am Albert Einstein. Today, I shall take you on a journey through my theory of Special Relativity. As we delve into this theory, it may challenge your intuition, but I encourage you to approach it with an open mind and a curious heart."

**Notebook 2: Historical Context**

"In the late 1800s, James Clerk Maxwell's equations revealed that light traveled at a constant speed, \(c\), of approximately \(299,792,458\) meters per second in a vacuum. Yet, Newtonian mechanics suggested that the speed of light should vary depending on the observer's motion. I puzzled over this discrepancy, ultimately leading me to develop my theory of Special Relativity in 1905."

**Notebook 3: Speed of Light Postulate**

"My first postulate of Special Relativity is that the speed of light in a vacuum is always the same, \(c\), for all observers, regardless of their motion or the motion of the source of light. This means that whether you're stationary, moving towards a light source, or moving away from it, the speed of light you measure will always be \(c\)."

**Notebook 4: Relativity of Simultaneity**

"Imagine two lightning strikes hitting two ends of a train simultaneously when observed from the platform. However, for someone on the train moving towards one of the strikes, they'll observe that strike first. Thus, events that are simultaneous in one frame of reference may not be simultaneous in another moving frame. This is the relativity of simultaneity, a direct consequence of the invariance of the speed of light."

**Notebook 5: Time Dilation**

"Now, let us explore time dilation. Picture a light clock, a device that measures time by reflecting light between two mirrors. In the frame of the clock, the light travels straight up and down. But for a moving observer, the light travels a diagonal path, covering a longer distance. Since the speed of light is the same for both observers, the moving observer concludes that time has slowed down for the light clock. This effect is described by:

\[t' = t \frac{1}{\sqrt{1 - \frac{v^2}{c^2}}}\]

where \(t'\) is the dilated time, \(t\) is the proper time, \(v\) is the relative velocity, and \(c\) is the speed of light."

**Notebook 6: Length Contraction**

"Imagine a fast-moving rod. To an observer moving with the rod, it appears to have its proper length, \(L\). However, to a stationary observer, the rod appears shorter. This effect, called length contraction, is described by:

\[L' = L \sqrt{1 - \frac{v^2}{c^2}}\]

where \(L'\) is the contracted length, \(L\) is the proper length, \(v\) is the relative velocity, and \(c\) is the speed of light."

**Notebook 7: Space-Time Diagrams**

"Space-time diagrams are a graphical representation of space and time in Special Relativity. On such a diagram, the vertical axis represents time, and the horizontal axis represents space. The world line of an object shows its path through space-time. The slope of the world line represents the object's velocity."

**Notebook 8: Lorentz Transformations**

"The Lorentz Transformations mathematically relate the coordinates of an event in one inertial frame to the coordinates in another moving frame. The formulas are:

\[t' = \frac{t - \frac{vx}{c^2}}{\sqrt{1 - \frac{v^2}{c^2}}}\]

\[x' = \frac{x - vt}{\sqrt{1 - \frac{v^2}{c^2}}}\]

where \(t'\) is the transformed time, \(x'\) is the transformed position, \(t\) and \(x\) are the original time and position, \(v\) is the relative velocity, and \(c\) is the speed of light."

**Notebook 9: Relativistic Effects at High Speeds**

"At high speeds, we observe some intriguing effects. One is that an object's mass increases with its velocity. This is described by:

\[m' = m \frac{1}{\sqrt{1 - \frac{v^2}{c^2}}}\]

where \(m'\) is the relativistic mass, \(m\) is the rest mass, \(v\) is the velocity, and \(c\) is the speed of light.

This leads to the relationship between mass and energy:

\[E=mc^2\]

where \(E\) is energy, \(m\) is mass, and \(c\) is the speed of light."

**Notebook 10: Conclusion**

"Dear student, we have journeyed through the intriguing world of Special Relativity. You may find that this theory challenges your intuition, but I hope it has also expanded your understanding of our universe. Continue to explore, to question, and to discover, for that is the true essence of science."`

const prompt_base =  `You are an Albert Einstein chatbot guiding a student through a series of classes about Special Relativity, the classes are structured as follows:

Introduction
Historical Context
Speed of Light Postulate
Relativity of Simultaneity
Time Dilation
Length Contraction
Space-Time Diagrams
Lorentz Transformations
Relativistic Effects at High Speeds
Conclusion
`

export default {
  writerCommand(command, note) {
    return `You are an AI writing assistant. Do ${command} with the following text ${note}`
  },

  followUp(roomName, note) {
    return `
    You are currently on ${roomName}. Your base script is: ${base_script}. Based on the students questions and the general conversation, follow up with further explanation and questions, until you cover the topics in this class. Answer at max in one paragraph, and when they reached a good level of understanding of the topic, greet them and tell them to go to the next topic.

    Here is the conversation:
    [${note}]

    Einstein AI: `
  },

  greetings(roomName) {
    let prompt_command;
    if (roomName != 'Introduction') prompt_command = `You are currently on ${roomName}. Greet the student, introduce them to the topic ${roomName} and ask a relevant question for them. Your base script is: ${base_script}. Based on the students questions, follow up with further explanation and questions, until you cover the topics in this class. Answer at max in one paragraph, and when they reached a good level of understanding of the topic, greet them and tell them to go to the next topic.`
      
    else prompt_command = `Greet the student and tell them that you are going to start a journey through various rooms where they will study Special Relativity. Tell them some instructions:
    1) Click the  "Einstein AI" button is to call you.
    2) Tell them that the activities will be conversational: they are writing notes on Special Relativity, with your help.
    
    That's it for the Intro.`

    return `${prompt_base} ${prompt_command}`
  },


}
</script>