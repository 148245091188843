<template>
  <v-card
    outlined
    rounded="xl"
    :color="navColor"
    :style="`height: 89vh; position: relative; border: ${borderStyle}`"
  >
    <v-container>
      <v-row justify="center">
        <v-col cols="11">
          <RoundedPlayer
            style="max-height: 200px;"
            videoId="demo-video"
            :url="samples[index].url"
            thumbnail="https://hisolverlab.s3.amazonaws.com/Animations/Screenshot+2023-10-24+at+6.56.05+AM.png"
            :options="{
              controls: true,
              autoplay: false,
              responsive: true,
              playbackRates: [0.5, 1, 1.5, 2] 
            }"
            :isPreview="true"
            :mediaViewer="true"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11">
          <ContentGenerator
            style="flex-grow: 1;" 
            @videoUrl="setVideo"
            @query="doQuery"
          />
        </v-col>
      </v-row>
    </v-container>    
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentGenerator from '@components/Study/ContentGenerator'
import { RoundedPlayer } from '@components/Video';
import API from '@api'

export default {
  components: {
    ContentGenerator,
    RoundedPlayer
  },

  data: () => ({
    index: 0,
    samples: [
      {
        name: 'Sum Squares 3D',
        url: 'https://hisolverlab.s3.amazonaws.com/MainScene.mp4',
        description: `The provided animation script depicts an illustrative approach to visually demonstrate a mathematical formula for the sum of squares, \(\sum_{i=1}^n i^2 = \frac{n(n+1)(2n+1)}{6}\), utilizing 3D geometric shapes, specifically cubes, arranged in various formations. Initially, a series of cubes are presented, each level representing the square of a certain number, which is then reshaped and rotated to form pyramid-like structures. These structures are manipulated, duplicated, and re-arranged to visually represent the algebraic expression \(n(n+1)(2n+1)\), showcasing the geometric basis of the formula. Through a series of rotations, translations, and color changes, the animation visually bridges the algebraic expression with the summation formula, providing a geometric intuition for the formula's derivation. This visual representation helps in understanding the geometric reasoning behind the mathematical expression for the sum of squares.`
      },
      {
        name: 'Square Mapping',
        url: 'https://hisolverlab.s3.amazonaws.com/Animations/Square+mapping.mp4',
        description: `The animation script provided demonstrates a visual representation of the function \( f(x) = x^2 \) by mapping values from a number line to another number line which represents the squared values. Initially, two number lines are created; one representing the \( x \) values ranging from -10 to 10, and the other representing the \( f(x) \) values ranging from -100 to 100. Dots are placed on both number lines corresponding to the \( x \) values and their squared \( f(x) \) values respectively. A line is then drawn between each pair of corresponding dots, visually representing the function mapping from \( x \) to \( f(x) = x^2 \). The lines and dots are color-coded to enhance visual understanding. The animation transitions from showing the \( x \) values on the upper number line, to drawing lines to the corresponding squared values on the lower number line, thereby illustrating the function mapping in a clear and visually engaging manner. This creates a vivid depiction of how each \( x \) value is mapped to its squared value \( x^2 \), providing an intuitive understanding of the function's behavior and the geometric interpretation of squaring a number.`
      },
      {
        name: 'Sum of the external angles of a triangle',
        url: 'https://hisolverlab.s3.amazonaws.com/Animations/Sum+of+the+external+angles+of+a+triangle.mp4',
        description: `The script visualizes the concept of the exterior angle of a triangle by extending a side of a triangle to form an exterior angle, and then demonstrates that this exterior angle is equal to the sum of the two opposite interior angles. Initially, a triangle is drawn and one of its sides is extended. Three arcs are then created to represent the exterior angle and the two opposite interior angles. Through animations, the script shifts and rotates the arcs representing the interior angles to visually show that together they form the exterior angle, thereby providing a geometric illustration of the exterior angle theorem in a clear and engaging manner.`
      },
      {
        name: 'Pythagorean fractals',
        url: 'https://hisolverlab.s3.amazonaws.com/Animations/Pythagoras+fractal.mp4',
        description: `In this script, a fractal visualization inspired by Pythagoras' Theorem is being created using Manim's OpenGL renderer. The \`PythagorasFractal\` class defines the structure and animation of the fractal through recursive function calls, creating a scene where each level of the fractal is generated and displayed in sequence. The visualization showcases the geometric relationship between the sides of right-angled triangles in a captivating, recursive pattern.`
      },
    ]
  }),

  computed: {
    ...mapGetters({
      myName: 'profile/name',
      myUsername: 'profile/username'
    }),

    navColor() {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-5'
        : '#1a1a1a'
    },
    
    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    }
  },

  methods: {
    async doQuery(query) {
      const response = await fetch(`${process.env.VUE_APP_LANGCHAIN_API}/hisolver_llm_call`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: false,
        body: JSON.stringify({
          stream: false,
          username: this.myUsername,
          user_name: this.myName,
          assistant_name: 'Anna',
          query: `
            Consider the following query: """${query}""""

            Now tell me which out of the following has a greater semantic proximity to the query:

            """${JSON.stringify(this.samples)}""""

            GIVE ME JUST THE INDEX OF THE OBJECT IN THE ARRAY i

            Give me the index of the object int the array in the following format:

            INDEX: n

            Where n is the index.
          `,
          msg_type: 'chat_msg',
          model_number: process.env.NODE_ENV === 'production' ? 2 : 1
        })
      })

      const { result } = await response.json()

      function findLastNumber(str) {
        var matches = str.match(/(\d+)(?!.*\d)/);
        return matches ? matches[0] : null;
      }

      var lastNumber = findLastNumber(result);
      
      this.index = Number(lastNumber)
    },
    setVideo({ videoUrl, thumbnailUrl }) {
      this.videoUrl = videoUrl
      this.thumbnailUrl = thumbnailUrl
    }
  }
}
</script>