var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:(("height: " + (_vm.rootHeight + 95) + "px;\n    position: relative;\n    border: " + _vm.borderStyle)),attrs:{"outlined":"","rounded":"xl","color":_vm.navColor}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{style:(("border-radius: 12px; background-color: " + (_vm.$vuetify.theme.dark ? 'rgba(255, 191, 16, 0.23)' : 'rgba(125, 33, 237, 0.157);'))),attrs:{"text":"","color":_vm.tagAnna || true ? _vm.$vuetify.theme.dark ? 'tertiary' : '#6200EA' : '',"disabled":_vm.saving},on:{"click":_vm.onWriteNoteClick}},[_vm._v(" "+_vm._s(_vm.buttonLabel)+" ")])],1),_c('div',{staticClass:"my-4"}),_c('ScrollPagination',{attrs:{"requestData":{
        'url': ("notebooks/" + (this.user.id)),
        'params': {
          'sender.profile': this.myProfileId,
          'sort': {
            'createdAt': -1
          }
        }
      },"countData":{
        'url': ("communities/" + (this.user.id) + "/count"),
        'params': {
          'sender.profile': this.myProfileId
        }
      },"rootHeight":((_vm.rootHeight + 20) + "px"),"autoHide":"never","rootStyle":'justify-content: start;',"setFatherItems":_vm.setNotebooks},scopedSlots:_vm._u([{key:"child",fn:function(props){return [_c('v-col',{key:("notebook_" + (props.index)),attrs:{"cols":"4"}},[_c('NoteCard',{staticClass:"mb-6",attrs:{"note":props.item,"setSaving":_vm.setSaving},on:{"select":function($event){return _vm.setNotebook(props.item)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }