<template>
  <v-card
    outlined
    rounded="xl"
    :color="navColor"
    :style="`height: ${rootHeight + 95}px;
      position: relative;
      border: ${borderStyle}`"
  >
    <v-container>
      <v-row justify="center">
        <v-btn
          text
          :color="tagAnna || true ? $vuetify.theme.dark ? 'tertiary' : '#6200EA' : ''"
          :style="`border-radius: 12px; background-color: ${ $vuetify.theme.dark ? 'rgba(255, 191, 16, 0.23)' : 'rgba(125, 33, 237, 0.157);'}`"
          @click="onWriteNoteClick"
          :disabled="saving"
        >
          {{ buttonLabel }}
        </v-btn>
      </v-row>

      <div class="my-4"></div>

      <ScrollPagination
        :requestData="{
          'url': `notebooks/${this.user.id}`,
          'params': {
            'sender.profile': this.myProfileId,
            'sort': {
              'createdAt': -1
            }
          }
        }"
        :countData="{
          'url': `communities/${this.user.id}/count`,
          'params': {
            'sender.profile': this.myProfileId
          }
        }"
        :rootHeight="`${rootHeight + 20}px`"
        autoHide="never"
        :rootStyle="'justify-content: start;'"
        :setFatherItems="setNotebooks"
      >
        <template v-slot:child="props">
          <v-col
            :key="`notebook_${props.index}`"
            cols="4"
          >
            <NoteCard
              class="mb-6"
              :note="props.item"
              @select="setNotebook(props.item)"
              :setSaving="setSaving"
            />
          </v-col>
        </template>
      </ScrollPagination>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ScrollPagination from '@components/App/ScrollPagination.vue'
import NoteCard from '@components/Note/NoteCard'

export default {
  components: {
    NoteCard,
    ScrollPagination
  },

  data: () => ({
    onBoardingOpen: false,
    saving: false
  }),

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      user: 'user',
      myProfileId: 'profile/id',
      notebooks: 'notebook/notebooks',
      pendingOnBoarding: 'pendingOnBoarding'
    }),

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 80
    },

    navColor() {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-5'
        : '#1a1a1a'
    },
    widthCalc() {
      return this.$vuetify.breakpoint.xl ? 99 : 98
    },
    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    },
    buttonLabel() {
      if (this.saving) return 'Saving...'
      return 'new note'
    }
  },

  methods: {
    ...mapMutations({
      SET_NOTEBOOKS: 'notebook/notebooks',
      SET_NOTEBOOK: 'notebook/notebook'
    }),

    setNotebooks(notebooks) {
      this.SET_NOTEBOOKS(notebooks)
    },

    setNotebook(notebook) {
      this.SET_NOTEBOOK(notebook)
      
      setTimeout(() => {
        this.$router.push(`notebooks/note?id=${notebook.id}`)
      }, 200)
    },

    onWriteNoteClick() {
      this.forwardWritenote()
    },

    forwardWritenote() {
      this.$router.push('notebooks/note')
    },
    setSaving(value) {
      this.saving = value
    }
  }
}
</script>