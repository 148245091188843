<template>
  <v-card
    elevation="0"
    :class="{
      'mx-auto hs-rounded-xl': true,
      'editor-dark': $vuetify.theme.dark
    }"
  >
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      @close="snackOn = false"
    />

    <v-container>
      <v-card-text>
        <div
          :id="`editorjs_${EDITOR_ID}`"
          spellcheck="false"
          class="generator-box hidden--class"
          style="z-index: 2; padding: 10px 10px 20px 20px;"
          ref="postEditor"
          @click="focus"
        ></div>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-btn
          large
          elevation="0"
          color="grey lighten-2"
          class="grey--text text--darken-4"
          style="border-radius: 12px;"
          :loading="loading"
          @click="submit"
        >
          animate
          <v-icon right>
            {{ mdiShimmer }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import API from '@api'
import { mapGetters } from 'vuex';
import { SnackBar } from '@components/App'
import { mdiSend, mdiShimmer } from '@mdi/js';
import DefaultEditorJS from '@components/DefaultEditorJS'
import { v4 as uuidv4 } from 'uuid';
const EDITOR_ID = uuidv4()

export default {
  components: {
    SnackBar
  },

  props: {
    post: {
      type: Object,
      default: () =>  ({})
    }
  },

  created() {
    this.setEditor()
  },
  
  data: () => ({
    mdiSend,
    mdiShimmer,
    EDITOR_ID: EDITOR_ID,
    snackMsg: '',
    snackOn: false,
    editor: null,
    loading: false
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      myName: 'profile/name',
      myUsername: 'profile/username',
      myAvatar: 'profile/avatar',
      myProfileId: 'profile/id',
    })
  },

  methods: {
    async createThumbnail(videoUrl) {
      return new Promise((resolve, reject) => {
        const videoElem = document.createElement("video");

        videoElem.src = videoUrl;
        videoElem.addEventListener("loadedmetadata", function() {
          // Seek to the last second of the video
          videoElem.currentTime = videoElem.duration - 1;
        });

        videoElem.addEventListener("timeupdate", function() {
          const canvas = document.createElement("canvas");
          canvas.width = videoElem.videoWidth;
          canvas.height = videoElem.videoHeight;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElem, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(blob => {
              resolve(URL.createObjectURL(blob));  // Return the thumbnail URL
          }, 'image/jpeg', 0.95);  // JPEG format at 95% quality
        });

        videoElem.addEventListener("error", reject);
      });
    },

    async submit() {
      this.loading = true
      const query = await this.editor.saver.save()
      this.$emit('query', query.blocks[0].data.text)
      this.loading = false

      return;
      
      const queryStr = `video_name=${encodeURIComponent(uuidv4())}&query=${encodeURIComponent(query.blocks[0].data.text)}`

      try {
        const res = await fetch(`${process.env.VUE_APP_LANGCHAIN_API}/gen_math_video?${queryStr}`);

        if (res.ok) {
          // Reading the ReadableStream and converting it to Blob
          const videoBlob = await res.blob();
          
          // Creating a URL for the Blob
          const videoUrl = URL.createObjectURL(videoBlob);
          const thumbnailUrl = await this.createThumbnail(videoUrl);
          this.$emit('videoUrl', { videoUrl, thumbnailUrl })
        }
      } catch (err) {
        this.snackMsg = 'manimErr'
        this.snackOn = true
      } finally {
        this.editor.clear()
      this.loading = false
      }
    },
    
    focus(evt) {
      if (evt.target.classList[0] != 'ce-paragraph')
        this.editor.caret.setToLastBlock('end')
    },

    async setEditor() {
      const editorPromise = DefaultEditorJS.newEditor(EDITOR_ID, 'What do you want to animate?')
      await editorPromise.isReady;
      this.editor = editorPromise;
    }
  }
}
</script>

<style>
.generator-box {
  cursor: text;
}
.editor-dark .generator-box {
  border-radius: 18px;
  background-color: #171717d3;
}
.generator-box {
  border-radius: 18px;
  background-color: #e7e6e62b;
}
</style>