<template>
  <v-card
    outlined
    rounded="xl"
    :color="navColor"
    :style="`height: ${rootHeight + 95}px;
      position: relative;
      border: ${borderStyle}`"
  >
    <v-container class="py-2">
      <v-row
        align="center"
        justify="start"
        class="px-1 pr-2"
      >
        <v-btn
          icon
          @click="onBackClick"
        >
          <v-icon>
            {{ mdiArrowLeft }}
          </v-icon>
        </v-btn>

        <div class="px-2"></div>

        <span class="font-weight-bold title-truncate">
          {{ title }}
        </span>

        <v-spacer />

        <v-btn
          outlined
          :color="tagAnna || true ? $vuetify.theme.dark ? 'tertiary' : '#6200EA' : ''"
          :style="`border-radius: 12px;`"
          @click="setWrite"
        >
          new note
        </v-btn>

        <div class="mx-2"></div>

        <v-btn
          text
          :loading="saving"
          :color="tagAnna || true ? $vuetify.theme.dark ? 'tertiary' : '#6200EA' : ''"
          :style="`border-radius: 12px; background-color: ${ $vuetify.theme.dark ? 'rgba(255, 191, 16, 0.23)' : 'rgba(125, 33, 237, 0.157);' }`"
          @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-row>
    </v-container>

    <v-divider />

    <v-fade-transition hide-on-leave>
      <v-container
        v-if="!showNotebook"
        style="height: 100%;"
      >
        <Scrollbar
          :thin="true"
          class="px-4"
          :style="`height: ${scrollHeight}vh;`"
        >
          <v-row justify="center">
            <v-col
              xl="8"
              lg="10"
              :class="{'viewer-dark': $vuetify.theme.dark}"
            >
              <markdown-it-vue
                class="md-body"
                :content="anotherContent"
                :options="options"
              />
            </v-col>
          </v-row>
        </Scrollbar>
      </v-container>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-container
        v-if="showNotebook"
        style="height: 100%;"
      >
        <Scrollbar
          :thin="true"
          autoHide="leave"
          class="px-4"
          :style="`height: ${rootHeight + 20}px;`"
        >
          <v-row justify="center">
            <v-col
              xl="8"
              lg="10"
              :class="`pl-16 ${$vuetify.theme.dark ? 'viewer-dark' : ''}`"
              id="notebook"
            >
              <div
                :id="`editorjs_${EDITOR_ID}`"
                spellcheck="false"
                @keydown="debounceOnKeyDown"
                ref="notebookCard"
              ></div> 
            </v-col>
          </v-row>
        </Scrollbar> 
      </v-container>
    </v-fade-transition>
  </v-card>
</template>

<script>
import API from '@api'
import { mapGetters, mapMutations } from 'vuex'
import { Scrollbar } from '@components/App'
import EditorJS from '@editorjs/editorjs'
import { mdiArrowLeft, mdiShimmer } from '@mdi/js'
import Paragraph from '@plugins/editorjs-paragraph/index.js'
import Button from '@plugins/editorjs-button/index.js'
// import LinkButton from '@plugins/editorjs-link-button/index.js'
import MarkdownItVue from 'markdown-it-vue/src/index.js'
import 'markdown-it-vue/dist/markdown-it-vue.css'
import { getWelcomeBlocks, convertToMarkdown, htmlToMarkdown } from '@utils'
import Prompts from '@components/Prompts'
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash'

const EDITOR_ID = uuidv4()
let SCROLL_EVENT;

export default {
  props: {
    scrollHeight: {
      type: Number,
      default () {
        return this.$vuetify.breakpoint.xl ? 81 : 80
      }
    },
    defaultTitle: {
      type: String,
      default: ''
    },
    welcome: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Scrollbar,
    MarkdownItVue
  },
  
  beforeDestroy() {
    window.removeEventListener('anyButtonClicked', this.handleAnyButtonClick);
    window.removeEventListener('scroll', SCROLL_EVENT);
    this.SET_NOTEBOOK({})
  },

  created() {
    this.preventDefaultBrowserCtrlS();
    this.debounceOnKeyDown = debounce((e) => this.onKeyDown(e), 1000)
    this.title = this.notebook.id ? this.notebook.title : this.defaultTitle ? this.defaultTitle : ''
  },

  async mounted() {
    window.addEventListener('anyButtonClicked', this.handleAnyButtonClick);

    // This prevents the page from scrolling when pasting content on the editor
    SCROLL_EVENT = window.addEventListener('scroll', () => {
      window.scrollTo(0, 0);
    });

    let blocks = [];

    if (this.welcome) blocks = getWelcomeBlocks(this)
    else if (this.notebook.id) {
      for (let block of this.notebook.content.blocks) {
        blocks.push(block)
      }
    }

    const editorPromise = new EditorJS({
      holder: `editorjs_${EDITOR_ID}`,
      readOnly: false,
      minHeight: 0,
      tools: {
        button: {
          class: Button,
          inlineToolbar: false,
          config:{
            css:{
              "btnColor": "btn--primary",
            }
          }
        },
        // linkbutton: {
        //   class: LinkButton,
        //   inlineToolbar: false,
        //   config:{
        //     css:{
        //       "btnColor": "btn--primary",
        //     }
        //   }
        // },
        paragraph: {
          class: Paragraph,
          config: {
            holder: `editorjs_${EDITOR_ID}`,
            isChat: false,
            placeholder: 'Press "Ctrl + /" for the AI command menu'
          }
        },

        header: {
          class: Header,
          inlineToolbar: ['marker', 'link'],
          config: {
            placeholder: 'Header'
          },
          shortcut: 'CMD+SHIFT+H'
        },

        /**
         * Or pass class directly without any configuration
         */
        image: SimpleImage,

        // image: ImageTool,

        list: {
          class: List,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+L'
        },

        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },

        quote: {
          class: Quote,
          inlineToolbar: true,
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
          },
          shortcut: 'CMD+SHIFT+O'
        },

        warning: Warning,

        marker: {
          class:  Marker,
          shortcut: 'CMD+SHIFT+M'
        },

        code: {
          class:  CodeTool,
          shortcut: 'CMD+SHIFT+C'
        },

        delimiter: Delimiter,

        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+C'
        },

        // For some reason Link works in Safari and Firefox
        // and LinkTool in Chrome.
        // Probably a lame ass version update or cdn bug.
        // Nov 8, 2023
        linkTool: window.LinkTool ? LinkTool : Link,

        embed: Embed,

        table: {
          class: Table,
          inlineToolbar: true,
          shortcut: 'CMD+ALT+T'
        },
      },

      data: {
        blocks
      }
    })
    await editorPromise.isReady;
    this.editor = editorPromise;

    // if (this.defaultTitle && this.tagAnna) {
    //   this.stream(Prompts.greetings(this.defaultTitle))
    // }
  },

  data: () => ({
    EDITOR_ID: EDITOR_ID,
    mdiShimmer,
    mdiArrowLeft,
    newRoomOn: false,
    showNotebook: true,
    saving: false,
    editor: {},
    title: '',
    tagAnna: true,
    options: {
      markdownIt: {
        linkify: true
      },
      katex: {
        throwOnError: false,
        errorColor: '#cc0000'
      },
      linkAttributes: {
        attrs: {
          target: '_blank',
          rel: 'noopener'
        }
      }
    },
    anotherContent: ``,
    autoSave: null
  }),

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      writerCommand: 'anna-mind/writerCommand',
      user: 'user',
      notebook: 'notebook/notebook',
      myName: 'profile/name',
      myUsername: 'profile/username',
      myAvatar: 'profile/avatar',
      myProfileId: 'profile/id',
      locale: 'locale'
    }),

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 80
    },

    widthCalc() {
      return this.$vuetify.breakpoint.xl ? 99 : 98
    },

    navColor() {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-5'
        : '#1a1a1a'
    },
    
    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    }
  },

  watch: {
    writerCommand(v) {
      if (v) {
        this.streamContext(v)
        setTimeout(() => {
          this.WRITER_COMMAND('')
        }, 100)
      }
    }
  },

  methods: {
    ...mapMutations({
      SET_NOTEBOOK: 'notebook/notebook',
      ADD_NOTEBOOK: 'notebook/addNotebook',
      WRITER_COMMAND: 'anna-mind/writerCommand'
    }),

    preventDefaultBrowserCtrlS(event) {
      document.addEventListener("keydown", function(e) {
        if (e.key === 's' && (navigator.userAgent.includes('Mac') ? e.metaKey : e.ctrlKey)) {
          e.preventDefault();
        }
      }, false);
    },

    onBackClick() {
      this.setWrite()
      this.$router.go(-1)
    },

    handleAnyButtonClick(event) {
      // You can access the event detail here if needed
      const { message } = event.detail;
      console.log('AnyButton was clicked:', message);

      

      const index = this.editor.blocks.getCurrentBlockIndex()

      this.editor.blocks.insert('header', {
        text: 'Type here the title of the Exercise',
        level: 3
      }, {}, index, false)

      this.editor.blocks.insert('paragraph', {
        text: 'Type here the content of the exercise. Use the checklist below if this is a multiple alternative exercise (leave the right options checked).'
      }, {}, index+1, false)

      this.editor.blocks.insert('checklist', {
        items: [
          { text: 'Item 1', checked: false },
          { text: 'Item 2', checked: false }
        ],
        // You can add any other properties required by your editor's checklist block
      }, {}, index+2, false);

      this.editor.blocks.insert('paragraph', {
        text: '<i>Type here a hint or a solution for this exercise</i>',
      }, {}, index+3, false)
    },

    async streamContext(writerCommand) {
      const output = await this.editor.save();
      const textBlocks = output.blocks
        .filter(block => block.type === 'paragraph' || block.type === 'header')
        .map(block => block.data.text);

      const note = htmlToMarkdown(textBlocks.join('\n'));
      //this.stream(Prompts.writerCommand(writerCommand, note))
    },

    async stream(prompt) {
      const index = this.editor.blocks.getCurrentBlockIndex()
      this.editor.blocks.getBlockByIndex(index).holder.firstChild.firstChild.classList.add('anna-text', 'active')

      return;
      
      // const response = await API('stream').post('anna/stream', { prompt })

      // const reader = response.body.getReader();

      // let loop = true;
      // const decoder = new TextDecoder("utf-8");
      // while(loop) {
      //   const {done, value} = await reader.read();
      //   if(done) {
      //     loop = false;

      //     setTimeout(() => {
      //       this.editor.blocks.getBlockByIndex(index).holder.firstChild.firstChild.classList.remove('active')
      //     }, 1000)
      //   } else {
      //     const chunkAsString = decoder.decode(value);
      //     this.editor.blocks.getBlockByIndex(index).holder.firstChild.firstChild.textContent += chunkAsString;
      //   }
      // }
    },

    setWrite() {
      this.SET_NOTEBOOK({})
      this.editor.clear()
      this.title = 'New notebook'
      this.clearAutoSave()
    },

    async save() {
      this.saving = true
      const saverData = await this.editor.saver.save()

      let notebook = this.notebook;

      const transcription = [];

      for (let block of saverData.blocks) {
        transcription.push({
          textContent: block.data.text
        })
      }

      const about = transcription.map(({ textContent }) => textContent).join(' ').slice(0, 250)
      const title = transcription[0].textContent

      if (this.notebook.id) {
        await API().put(`notebook/${this.user.id}`, {
          content: saverData,
          title,
          about
        }, {
          params: {
            query: {
              _id: {
                $eq: this.notebook.id
              }
            }
          }
        })

        notebook.content = saverData;
      } else {
        notebook = await API().post(`notebook/${this.user.id}`, {
          content: saverData,
          title,
          about,
          sender: {
            name: this.myName,
            username: this.myUsername,
            avatar: this.myAvatar,
            profile: this.myProfileId
          }
        })
        this.$router.replace({ query: { id: notebook._id } })
        this.ADD_NOTEBOOK(notebook)
      }

      this.SET_NOTEBOOK(notebook)

      this.anotherContent = convertToMarkdown(saverData.blocks)
      this.saving = false
    },

    toggleAIMenu() {
      
    },

    debounceOnKeyDown() {},

    clearAutoSave() {
      if (this.autoSave) clearTimeout(this.autoSave) 
    },

    async onKeyDown(evt) {
      if (evt.key === 's' && (evt.metaKey || evt.ctrlKey)) {
        evt.preventDefault()
        this.save()
        this.clearAutoSave()
      } else {
        if (this.$route.query.id) {
          this.autoSave = setTimeout(() => {
            this.save()
            this.clearAutoSave()
          }, 3000) 
        }
      }
    }
  }
}
</script>

<style>
.title-truncate {
  max-width: 50%;  /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;  /* This makes sure that the width property works for the span */
}
</style>