<template>
  <v-card
    outlined
    rounded="xl"
    :color="navColor"
    :style="`height: 35vh; border: ${borderStyle}`"
  >
    <v-container>
      <v-row
        justify="start"
        align="center"
        class="px-6 py-2"
      >
        <span class="text-h6">
          My Notes
        </span>

        <v-spacer />

        <v-btn
          text
          small
        >
          new note
        </v-btn>

        <v-btn
          text
          small
        >
          expand
          <v-icon
            right
            dark
          >
            {{ mdiChevronUp }}
          </v-icon>
        </v-btn>
      </v-row>
    
      <v-card-text>
        <div class="hs-container">
          <Scrollbar
            :thin="true"
            style="height: 22vh;"
          >
            <v-row class="hs-scroll-content">
              <v-card
                v-for="n in 15"
                :key="n"
                :color="sessionCardColor"
                :style="`border: ${borderStyle}`"
                class="ma-4"
                rounded="lg"
                min-width="250"
                outlined
              >
                <v-card-title :class="textColor">
                  {{ n === 1 ? 'ML' : n === 2 ? 'NLP Review' : 'GLL' }}
                </v-card-title>

                <v-card-text :class="`${textColor}`">
                  06/07/2023
                </v-card-text>

                <v-card-actions>
                  <v-progress-linear
                    color="primary"
                    rounded
                    value="40"
                  ></v-progress-linear>
                </v-card-actions>
              </v-card>
            </v-row>
          </Scrollbar>
        </div>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import Scrollbar from '@components/App/Scrollbar'
import { mdiChevronUp } from '@mdi/js'

export default {
  components: {
    Scrollbar
  },

  data: () => ({
    mdiChevronUp,
    model: null
  }),

  computed: {
    navColor() {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-5'
        : '#1a1a1a'
    },
    
    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    }
  }
}
</script>